@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import url('https://unicons.iconscout.com/release/v2.1.7/css/unicons.css');

@import './assets/scss/custom.scss';
@import './assets/scss/bootstrap.scss';
@import './assets/scss/style.scss';
@import "./assets/scss/features";

// drawer
.react-drawer-drawer {
  z-index: 9999;
  background: #fff !important;
  width: 350px !important;
}

.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}

.drawer-menu a i {
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  // transition: all 0.4s;
}

.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  // transition: all 0.4s;
}

.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.ReactModal__Overlay {
  z-index: 5000 !important;
}

.react-drawer-overlay {
  z-index: 1050;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #0a58ca;
  background-color: transparent;
}

.custom-badge-color {
  &.bg-soft-primary {
    color: $primary;
    background-color: rgba($primary, 0.1) !important;
  }
}

.carousel-overlay {
  .carousel-item {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $overlay;
    }
  }
}

.wicon a i {
  align-items: center;
  display: flex;
}

#style-switcher {
  transition: all .4s;
  background: none repeat scroll 0 0 $white;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  left: -189px;
  position: fixed;
  top: 11%;
  width: 189px;
  z-index: 9999;
  padding: 10px 5px;

  .bottom a.settings {
    background: none repeat scroll 0 0 $white;
    height: 41px;
    position: absolute;
    right: -41px;
    top: 100px;
    width: 40px;
    padding: 3px;
    border-radius: 0 30px 30px 0;
  }
}

.cookie-popup.alert {
  .btn-close {
    top: 17px;
    right: 17px;
  }
}

body {
  margin: 0;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

.rcw-conversation-container > .rcw-header {
  background-color: rgb(17, 45, 78);
}

.rcw-launcher {
  background-color: rgb(17, 45, 78);
}

.rcw-widget-container {
  bottom: 40px
}

.rcw-picker-icon {
  display: none;
}

.rcw-picker-btn {
  display: none;
}
