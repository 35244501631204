//
// Forms.scss
//

//Form
.form-label,
.form-check-label {
    font-weight: 700;
    font-size: 14px;
}

.form-control {
    border: 1px solid $border-color;
    font-size: 14px;
    line-height: 26px;
    border-radius: 6px;
    color: $dark !important;
    text-align: left;
    &:focus {
        border-color: $primary;
        box-shadow: none;
    }
    &[readonly] {
        background-color: $white;
    }
    &:disabled {
        background-color: $gray-300;
    }
    &::placeholder{
        color: $form-placeholder;
    }
}

.form-check-input {
    border: 1px solid $border-color;
    background-color: $form-check-input-bg;
    &:focus {
        border-color: $primary;
        box-shadow: none;
    }
    &.form-check-input:checked {
        background-color: $primary;
        border-color: $primary;
    }
}

//Subscribe form
.subcribe-form {
    .btn {
        padding: 6px 10px;
    }
    input {
        padding: 12px 20px;
        width: 100%;
        color: $dark !important;
        border: none;
        outline: none !important;
        padding-right: 160px;
        padding-left: 30px;
        height: 42px;
        &::placeholder{
            color: $form-placeholder;
        }
    }
    button {
        position: absolute;
        top: 1px;
        right: 1px;
        outline: none !important;
    }
    form {
        position: relative;
        max-width: 600px;
        margin: 0px auto;
    }
}
