//
// text.scss
//

@each $name,
$value in $theme-colors {
    .text-#{$name} {
        color: #{$value} !important;
    }
    a {
        &.text-#{$name} {
            &:hover,
            &:focus {
                color: darken($value, 5%) !important;
            }
        }
    }
}

.text-small  {
    font-size: 14px;
}

.text-smallest  {
    font-size: 11px;
}

.text-align-center {
    text-align: center;
}
